import React from 'react'
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Home24Regular, Organization24Regular, DeviceEq24Regular, People24Regular, DeviceMeetingRoom24Regular, ChartMultiple24Regular, Location24Regular, AppsList24Regular, Settings24Regular, GroupList24Regular, AppGeneric24Regular, DatabasePerson24Regular, Alert24Regular, Map24Regular, EyeOff24Filled, Eye24Filled } from '@fluentui/react-icons'
import OrgList from './org'
import UserList from './users';
import DeviceList from './devices';
import DeviceTypeList from './deviceTypes';
import PlanList from './plans';
import { FluentProvider, Text, teamsDarkTheme, teamsLightTheme, Avatar, TabList, Tab, SelectTabData, SelectTabEvent, Option, Dropdown, webDarkTheme, webLightTheme, Dialog, DialogSurface, DialogContent, Button, DialogActions, DialogTrigger, DialogBody, DialogTitle, Field, Input, Label, ButtonProps, InfoLabel, LabelProps } from "@fluentui/react-components";
import { State } from './types';
import { lightTheme, darkTheme } from './theme';
import LocationList from './locations';
import HomeMenu from './home';
import { Settings } from './settings';
import DeviceDataCharts from './deviceData';
import DeviceAlarmList from './alarms';
import DeviceMap from './deviceMap';
import logoLight from './agora-logo-on-black.png'
import logoDark from './agora-logo-on-white.png'
import AppList from './apps';
import { isLoggedIn, logIn } from './utils/api';

const emptyState: State = { Org: null, User: null, Device: null }
const availableThemes = [
	{ Name: "Default Light", theme: lightTheme, logo: logoLight },
	{ Name: "Default Dark", theme: darkTheme, logo: logoDark },
	{ Name: "Teams Light", theme: teamsLightTheme, logo: logoLight },
	{ Name: "Teams Dark", theme: teamsDarkTheme, logo: logoDark },
	{ Name: "Web Light", theme: webLightTheme, logo: logoLight },
	{ Name: "Web Dark", theme: webDarkTheme, logo: logoDark },
]

export const GlobalContext = React.createContext({ state: emptyState, setState: (state: State) => { } });

interface NavigationProps {
	navigateTo: string
}

const Navigation: React.FC<NavigationProps> = (props) => {

	const navigate = useNavigate()

	React.useEffect(() => {
		if (props.navigateTo) {
			navigate(props.navigateTo);
		}
	}, [props, navigate]);

	return (<React.Fragment></React.Fragment>)
}

interface TabProps {
	value: string
	icon: React.ReactElement
	text: string
}

const SpacedTab = (props: TabProps) => <Tab style={{ padding: '8px 12px' }} value={props.value} icon={props.icon}>{props.text}</Tab>

const App = () => {
	const [globalState, setGlobalState] = React.useState<State>(emptyState)
	const [currentTheme, setCurrentTheme] = React.useState(lightTheme)
	const [navigateTo, setNavigateTo] = React.useState("")
	const [currentThemeName, setCurrentThemeName] = React.useState("Web Light")
	const [currentLogo, setCurrentLogo] = React.useState(logoLight)
	const iconColor = currentTheme.colorBrandForeground2Hover
	const [username, setUsername] = React.useState("ostump@gmail.com")
	const [userFullName, setUserFullName] = React.useState("")
	const [fullName, setFullName] = React.useState("")
	const [regEmail, setRegEmail] = React.useState("")
	const [userPrimaryEmail, setUserPrimaryEmail] = React.useState("")
	const [phoneNumber, setPhoneNumber] = React.useState("")
	const [password, setPassword] = React.useState("876u4b&8645&y")
	const [password1, setPassword1] = React.useState("")
	const [password2, setPassword2] = React.useState("")
	const [message, setMessage] = React.useState<string>("")
	const [loggedIn, setLoggedIn] = React.useState(false)
	const [showPassword, setShowPassword] = React.useState(false)
	const [showPassword1, setShowPassword1] = React.useState(false)
	const [showPassword2, setShowPassword2] = React.useState(false)
	const [registeringUser, setRegisteringUser] = React.useState(false)
	/*
	const [authContext, setAuthContext] = React.useState<AgAuthContext | null>(null)
	if (null === authContext) {
		const user = new AgAuthContext()
		user.login('olst@outlook.com', '876u4b&8645&y').then(() => {
			setAuthContext(user)
		})
	}
*/
	const validatePassword1 = (pwd: string) => {
		if (pwd.length < 8)
			setMessage("Password is too short")
		else if (pwd.length > 30)
			setMessage("Password is too long")
		else if (!pwd.match(/[A-Z]/))
			setMessage("Password must contain at least 1 uppercase letter")
		else if (!pwd.match(/[a-z]/))
			setMessage("Password must contain at least 1 lowercase letter")
		else if (!pwd.match(/[0-9]/))
			setMessage("Password must contain at least 1 number")
		else if (!pwd.match(/[!@#\$%\^&\*\-_\+=<>:"\|]/))
			setMessage("Password must contain at least 1 symbol")
		else if (pwd !== password2)
			setMessage("Passwords do not match")
		else
			setMessage("")

		setPassword1(pwd)
	}

	const validatePassword2 = (pwd: string) => {
		if (pwd !== password1)
			setMessage("Passwords do not match")
		else
			setMessage("")

		setPassword2(pwd)
	}

	const ShowPasswordButton: React.FC<ButtonProps> = (props) => {
		return (
			<Button
				{...props}
				appearance="transparent"
				icon={showPassword ? <EyeOff24Filled /> : <Eye24Filled />}
				size="small"
			/>
		);
	};

	const doLogin = async () => {
		const user = await logIn(username, password)
		if (user) {
			setUserFullName(user.Name ?? '')
			setUserPrimaryEmail(user.PrimaryEmail ?? '')
			setMessage('Log in successful')
			setLoggedIn(true)
		}
		else {
			setMessage('Log in failed')
		}
	}

	const doRegister = async () => {
		// TODO: Make register
		const user = await logIn(username, password)
		if (user) {
			setUserFullName(user.Name ?? '')
			setUserPrimaryEmail(user.PrimaryEmail ?? '')
			setMessage('Log in successful')
			setLoggedIn(true)
		}
		else {
			setMessage('Log in failed')
		}
	}

	const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
		setNavigateTo(data.value as string)
	}

	return (
		<FluentProvider theme={currentTheme}>
			<BrowserRouter>
				<GlobalContext.Provider value={{ state: globalState, setState: setGlobalState }}>
					<div style={{ backgroundColor: currentTheme.colorBrandForeground2, color: 'white', margin: 0, padding: '20px', width: '100vw' }}>
						<div style={{ display: "flex", alignItems: 'bottom', float: 'left', fontSize: '12pt' }}>
							<img
								style={{ marginTop: '-4px', marginRight: '64px', marginLeft: '-12px' }}
								height='56px' alt="Agora logo"
								src={currentLogo}
							/>
							<div><strong>Agora IOT</strong>&nbsp;<br /><span style={{ fontSize: '10pt' }}>Admin Console</span></div>
						</div>

						<div style={{ display: "flex", alignItems: 'center', alignContent: 'right', justifyContent: 'right', fontSize: '10pt', paddingRight: '64px' }}>
							<Dropdown value={currentThemeName} style={{ minWidth: '140px', marginRight: '32px' }}
								onOptionSelect={(e, d) => {
									const newTheme = availableThemes.find(athm => athm.Name === d.optionText)
									setCurrentTheme(newTheme?.theme || lightTheme)
									setCurrentLogo(newTheme?.logo || logoLight)
									setCurrentThemeName(d.optionText || "")
								}}>
								{availableThemes.map(thm => (<Option key={thm.Name} text={thm.Name} value={thm.Name}>{thm.Name}</Option>))}
							</Dropdown>
							<Avatar></Avatar>&nbsp;&nbsp;
							<div><strong>{userFullName}</strong><br />{userPrimaryEmail}</div>
						</div>
					</div>

					<Navigation navigateTo={navigateTo} />
					{loggedIn && <div style={{ display: 'flex', alignItems: 'stretch', minHeight: '100vh', height: '100vh' }}>
						<div style={{ backgroundColor: currentTheme.colorNeutralBackground3, display: 'flex', alignItems: 'stretch', minHeight: '100vh', minWidth: '240px' }}>

							<br />

							<TabList vertical style={{ padding: '12px', display: 'flex', justifyItems: 'flex-end', alignContent: 'top', color: 'white', height: '100vh', minWidth: '200px' }} onTabSelect={onTabSelect}>
								<SpacedTab value="/" icon={<Home24Regular color={iconColor} />} text="Home" />
								<SpacedTab value="orgs" icon={<Organization24Regular color={iconColor} />} text="Organizations" />
								<SpacedTab value="users" icon={<People24Regular color={iconColor} />} text="Users" />
								<SpacedTab value="roles" icon={<DatabasePerson24Regular color={iconColor} />} text="Roles & Permissions" />
								<SpacedTab value="devices" icon={<DeviceMeetingRoom24Regular color={iconColor} />} text="Devices" />
								<SpacedTab value="devicemap" icon={<Map24Regular color={iconColor} />} text="Device Map" />
								<SpacedTab value="devicedata" icon={<ChartMultiple24Regular color={iconColor} />} text="Device Data Analysis" />
								<SpacedTab value="alarms" icon={<Alert24Regular color={iconColor} />} text="Device Alarms" />
								<SpacedTab value="devicetypes" icon={<DeviceEq24Regular color={iconColor} />} text="Device Types" />
								<SpacedTab value="devicecohorts" icon={<GroupList24Regular color={iconColor} />} text="Device Groups" />
								<SpacedTab value="locations" icon={<Location24Regular color={iconColor} />} text="Locations" />
								<SpacedTab value="apps" icon={<AppGeneric24Regular color={iconColor} />} text="Apps" />
								<SpacedTab value="plans" icon={<AppsList24Regular color={iconColor} />} text="Plans & Licenses" />
								<div style={{ flexGrow: 1 }} />
								<SpacedTab value="settings" icon={<Settings24Regular color={iconColor} />} text="Settings" />
							</TabList>

						</div>
						<div style={{ minHeight: '100%' }}>
							<Routes>
								<Route path="/" element={<HomeMenu />} />
								<Route path="/orgs" element={<OrgList />} />
								<Route path="/users" element={<UserList />} />
								<Route path="/devices" element={<DeviceList />} />
								<Route path="/devicemap" element={<DeviceMap />} />
								<Route path="/devicedata" element={<DeviceDataCharts />} />
								<Route path="/alarms" element={<DeviceAlarmList />} />
								<Route path="/devicetypes" element={<DeviceTypeList />} />
								<Route path="/devicegroups" element={<DeviceTypeList />} />
								<Route path="/plans" element={<PlanList />} />
								<Route path="/locations" element={<LocationList />} />
								<Route path="/apps" element={<AppList />} />
								<Route path="/settings" element={<Settings />} />
							</Routes>
						</div>
					</div>}

					<Dialog open={!loggedIn && !registeringUser}>
						<DialogSurface>
							<DialogBody>
								<DialogTitle>Log In</DialogTitle>
								<DialogContent>
									<br />
									<br />
									<Field orientation="horizontal" label="Username">
										<Input name="Username" type="text" value={username} onChange={e => setUsername(e.target.value)} />
									</Field>
									<br />
									<Field orientation="horizontal" label="Password">
										<Input name="Password" type={showPassword ? "text" : "password"} value={password}
											contentAfter={<ShowPasswordButton onClick={() => setShowPassword(!showPassword)} />}
											onChange={e => setPassword(e.target.value)} />
									</Field>
									<br />
									<Text style={{ color: loggedIn ? 'green' : 'red', fontSize: '9pt' }}>{message}&nbsp;</Text>
								</DialogContent>
								<DialogActions>
									<Button appearance="secondary" onClick={() => setRegisteringUser(true)}>Register</Button>
									<DialogTrigger disableButtonEnhancement>
										<Button appearance="primary" onClick={() => doLogin()}>Log In</Button>
									</DialogTrigger>
								</DialogActions>
							</DialogBody>
						</DialogSurface>
					</Dialog>

					<Dialog open={!loggedIn && registeringUser}>
						<DialogSurface>
							<DialogBody>
								<DialogTitle>Register New Account</DialogTitle>
								<DialogContent>
									<br />
									<br />
									<Field orientation="horizontal" label="Username (Email)" required>
										<Input name="Username (Email)" type="text" value={regEmail} onChange={e => setRegEmail(e.target.value)} />
									</Field>
									<br />
									<Field orientation="horizontal" label="Full Name" required>
										<Input name="Full Name" type="text" value={fullName} onChange={e => setFullName(e.target.value)} />
									</Field>
									<br />
									<Field orientation="horizontal" label="Mobile Phone Number">
										<Input name="PhoneNunmber" type="text" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
									</Field>
									<br />
									<Field orientation="horizontal" label="Password">
										<InfoLabel info="Password must be 8-30 characters and contain at least 1 of each uppercase letters, lowercase letters, numbers and symbols !@#$%^&*()" />
										<Input name="Password" type={showPassword1 ? "text" : "password"} value={password1}
											contentAfter={<ShowPasswordButton onClick={() => setShowPassword1(!showPassword1)} />}
											onChange={e => validatePassword1(e.target.value)} />
									</Field>
									<br />
									<Field orientation="horizontal" label="Re-type Password" required>
										<Input name="Password" type={showPassword2 ? "text" : "password"} value={password2}
											contentAfter={<ShowPasswordButton onClick={() => setShowPassword2(!showPassword2)} />}
											onChange={e => validatePassword2(e.target.value)} />
									</Field>
									<br />
									<Text style={{ color: 'red', fontSize: '9pt' }}>{message}&nbsp;</Text>
								</DialogContent>
								<DialogActions>
									<Label>Have an account?</Label>
									<Button appearance="secondary" onClick={() => setRegisteringUser(false)}>Log In</Button>
									<DialogTrigger disableButtonEnhancement>
										<Button appearance="primary" onClick={() => doRegister()}>Register</Button>
									</DialogTrigger>
								</DialogActions>
							</DialogBody>
						</DialogSurface>
					</Dialog>

				</GlobalContext.Provider>
			</BrowserRouter>
		</FluentProvider>
	);
}

export default App