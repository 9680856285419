import React from 'react'
import { App, PanelProps } from './types'
import { Button, ButtonProps, InfoLabel, Input, Label, makeStyles, shorthands } from '@fluentui/react-components'
import { Drawer } from '@fluentui/react-components/unstable'
import Grid from '@mui/joy/Grid'
import { Eye24Filled, EyeOff24Filled, ShareScreenStop24Filled } from '@fluentui/react-icons'


export interface AppPanelProps extends PanelProps<App> {

}

const useStyles = makeStyles({
	root: {
		// Stack the label above the field
		display: "flex",
		flexDirection: "column",
		// Use 2px gap below the label (per the design system)
		...shorthands.gap("2px"),
		// Prevent the example from taking the full width of the page (optional)
		//maxWidth: "100%",
		fontSize: '10pt'
	},
});

const AppPanel: React.FC<AppPanelProps> = (props) => {
	const app = props.selectedObject
	const [theApp, setTheApp] = React.useState<App | null>(app)
	const [showPassword, setShowPassword] = React.useState(false)

	React.useEffect(() => {
		setTheApp(props.selectedObject)
		//console.log("Updating theApp")
	}, [props])

	//console.log(theApp?.Name || "bit funny, this")

	const ShowPasswordButton: React.FC<ButtonProps> = (props) => {
		return (
			<Button
				{...props}
				appearance="transparent"
				icon={showPassword ? <EyeOff24Filled /> : <Eye24Filled />}
				size="small"
			/>
		);
	};

	const styles = useStyles();

	console.log("App in panel is", props.selectedObject)
	return (
		<Drawer open={props.open} position='end' size='medium'>
			<div style={{ padding: '32px', width: '500px' }}>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div><strong>USER</strong></div>
					<div><ShareScreenStop24Filled style={{ cursor: "pointer", color: "red" }} onClick={() => props.onClose()} /></div>
				</div>
				<br /><br /><br />

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt', float: 'left' }} htmlFor="orgId">Id</Label>
							<InfoLabel info="The app id cannot be changed" style={{ fontSize: '8pt', float: 'left' }} />
						</Grid>
						<Grid xs={9}>
							<Input appearance='underline' required id="orgId" value={theApp?.AppId || ''} disabled style={{ width: '100%' }} /><br />
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }} htmlFor="orgName">Name</Label>
						</Grid>
						<Grid xs={9}>
							<Input appearance='underline' required id="orgName" value={theApp?.Name || ''} onChange={(e, d) => { const app = theApp; if (app) app.Name = d.value; setTheApp(app) }} style={{ width: '100%' }} />
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }} htmlFor="webHook">Webhook URI</Label>
						</Grid>
						<Grid xs={9}>
							<Input appearance='underline' required id="webHook" value={theApp?.WebHookUri || ''} onChange={(e, d) => { const app = theApp; if (app) app.WebHookUri = d.value; setTheApp(app) }} style={{ width: '100%' }} />
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }} htmlFor="webHookSecret">Webhook Secret</Label>
						</Grid>
						<Grid xs={9}>
							<Input appearance='underline'
								required
								id="webHookSecret"
								type={showPassword ? "text" : "password"}
								contentAfter={<ShowPasswordButton onClick={() => setShowPassword(!showPassword)} />}
								value={theApp?.WebHookSecret || ''}
								onChange={(e, d) => { const app = theApp; if (app) app.WebHookUri = d.value; setTheApp(app) }}
								style={{ width: '100%' }} />
						</Grid>
					</Grid>
				</div>

				<br /><br /><br />

				<Button appearance='primary' onClick={() => props.onClose()}>Close</Button>
			</div>
		</Drawer>
	)
}

export default AppPanel