/*
**	AGORA Admin UI. (c) 2024 Silver Falls Capital. All Rights Reserved
**
**	This code is unlicensed and may not be copied, used, modified,
**	compiled, run, or distributed in any form without the explicit
**	written permission of Silver Falls Capital. 
**
**	Author(s): Oliver Sturrock olst@silverfallscapital.com
**
**/

import React from 'react'
import { Device } from './types'
import GoogleMap from 'google-maps-react-markers'
import { Pin24Filled } from '@fluentui/react-icons'
import { tokens } from '@fluentui/react-theme';
import callApi from './utils/api'

interface MarkerProps {
	className?: string
	lat: number
	lng: number
	markerId: string
	onClick?: (
		e: React.MouseEvent<HTMLImageElement, MouseEvent>,
		props: { lat: number; lng: number; markerId: string },
	) => void
}

const mapDarkStyles =  [
	{ elementType: "geometry", stylers: [{ color: "#242f3e" }] },
	{ elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
	{ elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
	{
	  featureType: "administrative.locality",
	  elementType: "labels.text.fill",
	  stylers: [{ color: "#d59563" }],
	},
	{
	  featureType: "poi",
	  elementType: "labels.text.fill",
	  stylers: [{ color: "#d59563" }],
	},
	{
	  featureType: "poi.park",
	  elementType: "geometry",
	  stylers: [{ color: "#263c3f" }],
	},
	{
	  featureType: "poi.park",
	  elementType: "labels.text.fill",
	  stylers: [{ color: "#6b9a76" }],
	},
	{
	  featureType: "road",
	  elementType: "geometry",
	  stylers: [{ color: "#38414e" }],
	},
	{
	  featureType: "road",
	  elementType: "geometry.stroke",
	  stylers: [{ color: "#212a37" }],
	},
	{
	  featureType: "road",
	  elementType: "labels.text.fill",
	  stylers: [{ color: "#9ca5b3" }],
	},
	{
	  featureType: "road.highway",
	  elementType: "geometry",
	  stylers: [{ color: "#746855" }],
	},
	{
	  featureType: "road.highway",
	  elementType: "geometry.stroke",
	  stylers: [{ color: "#1f2835" }],
	},
	{
	  featureType: "road.highway",
	  elementType: "labels.text.fill",
	  stylers: [{ color: "#f3d19c" }],
	},
	{
	  featureType: "transit",
	  elementType: "geometry",
	  stylers: [{ color: "#2f3948" }],
	},
	{
	  featureType: "transit.station",
	  elementType: "labels.text.fill",
	  stylers: [{ color: "#d59563" }],
	},
	{
	  featureType: "water",
	  elementType: "geometry",
	  stylers: [{ color: "#17263c" }],
	},
	{
	  featureType: "water",
	  elementType: "labels.text.fill",
	  stylers: [{ color: "#515c6d" }],
	},
	{
	  featureType: "water",
	  elementType: "labels.text.stroke",
	  stylers: [{ color: "#17263c" }],
	},
 ]

const Marker = ({ className, lat, lng, markerId, onClick, ...props }: MarkerProps) =>
	lat && lng ? (<Pin24Filled color={tokens.colorBrandForeground2Hover} />) : null


const SimpleMap = ({ devices }: { devices: Device[] }) => {

	return (
		// Important! Always set the container height explicitly
		<div style={{ height: '100vh', width: '100vw' }}>
			<GoogleMap
				apiKey="AIzaSyBGqbcPNA9GvizBs6pnGemszv5d8krEUkI"
				defaultCenter={{ lat: 47.741599, lng: -122.157345 }}
				defaultZoom={18}
				options={{style: mapDarkStyles}}
			>
				{devices.map(dev =>
					<Marker
						key={dev.DeviceId}
						lat={dev.Lat}
						lng={dev.Long}
						markerId={dev.Name}
					//onClick={onMarkerClick} // you need to manage this prop on your Marker component!
					// draggable={true}
					// onDragStart={(e, { latLng }) => {}}
					// onDrag={(e, { latLng }) => {}}
					// onDragEnd={(e, { latLng }) => {}}
					/>
				)}
			</GoogleMap>
		</div>
	);
}

const DeviceMap = () => {
	const [devices, setDevices] = React.useState<Device[]>([])

	const getDevices = async () => {
		let d = await callApi('devices')
		d = d.reverse()
		setDevices(d as unknown as Device[])
	}

	React.useEffect(() => {
		getDevices()
	}, [])

	return (
		<div>
			<SimpleMap devices={devices} />
		</div>
	)
}

export default DeviceMap
