import React from 'react'
import { DeviceData, DeviceMetadata, MetadataType } from './types'
import { Label, makeStyles, Radio, RadioGroup, tokens, useId, Checkbox } from "@fluentui/react-components";
import moment from 'moment'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import callApi from './utils/api'
import { API_BASE } from './env';

const AnnoAxisTemplate = {
	x: new Date('23 Nov 2017').getTime(),
	strokeDashArray: 2,
	borderColor: '#333',
	label: {
		borderColor: '#333',
		borderRadius: 4,
		style: {
			color: '#fff',
			background: '#333',
		},
		text: '',
	}
}

const AnnotationTemplate = {
	x: new Date('18 Dec 2023 14:05').getTime(),
	y: 86.55,
	marker: {
		size: 2,
		fillColor: '#fff',
		strokeColor: 'green',
		radius: 2,
	},
	label: {
		borderColor: '#00C560',
		offsetY: 0,
		borderRadius: 16,
		style: {
			color: '#fff',
			background: '#00C560',
		},
		text: 'Filter Replaced',
	},
	colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']
}


const useStyles = makeStyles({
	field: {
		display: "grid",
		gridRowGap: tokens.spacingVerticalS,
	},
});

const lineColors = ['#FF0000', '#00FF00', '#0000FF', '#FFC000', '#C0FF00', '#C000FF', '#FF00C0', '#00FFC0', '#00C0FF']
const DeviceDataCharts = () => {
	const [deviceData, setDeviceData] = React.useState<DeviceData[]>([])
	const [metadata, setMetadata] = React.useState<DeviceMetadata[]>([])
	const [selectedFields, setSelectedFields] = React.useState<DeviceMetadata[]>([])
	const [deviceId, setDeviceId] = React.useState('11c7c560-fc3e-4c32-b071-81ae3143257d')
	const [deviceTypeId, setDeviceTypeId] = React.useState('d0d7891d-e242-4a69-b36f-4781bc16168a')
	const [series, setSeries] = React.useState([] as any[])
	const [chartScale, setChartScale] = React.useState("2.0")		// hours on chart
	const [wsClient, setWsClient] = React.useState<WebSocket | null>(null)
	const [options, setOptions] = React.useState<ApexOptions>({
		//title: { text: 'Device Data over Time' },
		chart: { type: 'line', fontFamily: '"Aptos Display", Helvetica, Arial, sans-serif', group: 'moo', toolbar: { show: false } },
		stroke: { width: 5, curve: 'stepline', colors: ['#FF0000'] },
		xaxis: {
			min: Date.now() - 2 * 60 * 60 * 1000,
			max: Date.now(),
			tickAmount: 10,
			type: 'datetime',
			labels: {
				formatter: function (value: string) { return [moment(value).format('MM/DD/YYYY'), moment(value).format('HH:mm:ss')] }
			},
			tooltip: {
				enabled: false
			}
		},
		yaxis: {
			min: 0,
			max: 1,
			stepSize: 1,
			labels: { formatter: function (value: number) { return Math.floor(value).toString() } }
		},
		annotations: {
			yaxis: [],
			xaxis: [],
			points: []
		},
		tooltip: {
			followCursor: true,
			theme: 'dark',
		},
		grid: {
			yaxis: {
				lines: {
					show: false
				}
			},
			row: {
				colors: ['white'],
				opacity: 0.5
			},
			padding: {
				left: 30, // or whatever value that works
				right: 30 // or whatever value that works
			}
		},
		dataLabels: {
			enabled: true,
		},
		markers: {
			size: 5,
		},
		forecastDataPoints: {
			count: 0,
			fillOpacity: 0.5,
			strokeWidth: 3,
			dashArray: 4,
		},
		noData: {
			text: "No data to display",
			align: 'center',
			verticalAlign: 'middle',
			offsetX: 0,
			offsetY: 0,
			style: {
				color: undefined,
				fontSize: '14px',
				fontFamily: undefined
			}
		}
	})

	const ddStateRef = React.useRef<DeviceData[]>();
	ddStateRef.current = deviceData;

	const getDeviceData = async () => {
		//console.log(`getDD`)
		let d: any = null
		try {
			d = await callApi(`devices/${deviceId}/data`)
		}
		catch (e) {
			console.log("exception:", e)
		}

		if (!d)
			return

		//console.log("big d", JSON.stringify(d, null, 2))

		const ddRows = d.reverse() as unknown as DeviceData[]
		setDeviceData(ddRows)
		//console.log('ddRows gdd', JSON.stringify(ddRows))

		if (ddRows.length) {
			const fieldNames = Object.keys(ddRows[0].Data)
			const newSeries = fieldNames.map(md => {
				return {
					name: md,
					data: ddRows.filter(row => !!Object.keys(row.Data).find(key => key === md))
						.map(row => { return { x: new Date(row.CreatedAt), y: ((Object.entries(row.Data).find(entry => entry[0] === md)) || [0, 1])[1] } })
				}
			})
			if (newSeries[0] && newSeries[0].data?.length)
				newSeries[0].data.push({ x: new Date(), y: newSeries[0].data[newSeries[0].data.length - 1].y })

			setSeries(newSeries)
			//console.log(`set new series, len ${newSeries.length} with ${selectedFields.length} selected and ${metadata.length} meta`)
		}

		// Generate Points of Interest from non-number fields
		const points: any[] = [], xaxis: any[] = []
		metadata.forEach(field => {
			if (field.Type === MetadataType.Enum || field.Type === MetadataType.String) {

				let lastValue: number | string | null = null
				ddRows.forEach(dd => {
					const data = dd.Data as any
					if (data[field.Name] !== undefined) {

						const value = data[field.Name]
						//console.log("values", value, lastValue)
						if (lastValue !== null && value !== lastValue) {
							// Create annotation
							const anno = { ...AnnotationTemplate }
							anno.x = new Date(dd.CreatedAt).getTime()
							anno.y = value
							anno.label.text = `${field.Name} = ${value.toString()}`
							points.push(anno)

							const anno2 = { ...AnnoAxisTemplate, label: { ...AnnoAxisTemplate.label } }
							anno2.x = new Date(dd.CreatedAt).getTime()
							anno2.label.text = field.Description + ': ' + lastValue.toString() + ' ==> ' + value.toString()
							xaxis.push(anno2)
						}
						lastValue = value
					}
				})
			}
		})
		const newOptions = {
			...options,
			annotations: {
				xaxis: xaxis
				//points: points
			}
		}
		setOptions(newOptions)
	}

	const getData = async (DeviceTypeId: string, DeviceId: string) => {
		//console.log(`getD`)
		callApi(`devicetypes/${DeviceTypeId}/metadata`)
			.then(res => {
				if (res) {
					const metadata = (res as DeviceMetadata[]).filter(md => md.IsTimeSeries)
					setMetadata(metadata);

					const selFields = metadata	//.filter(md => md.Type === MetadataType.Number).filter(md => md.Name.includes('ilter'))
					if (selectedFields !== selFields) {
						//console.log(`resetting selectedFields with ${selFields.length} fields`)
						setSelectedFields(selFields)
					}
				}
			})

		if (wsClient === null) {
			try {
				let wsUri = (API_BASE.includes('https') ? 'wss' : 'ws') + API_BASE.slice(API_BASE.indexOf('://'))
				const sufIx = wsUri.indexOf(':8') > -1 ? wsUri.indexOf(':8') : wsUri.indexOf('/api')
				wsUri = wsUri.slice(0, sufIx) + ':8787/'
				console.log('wsUri', wsUri)

				const client = new WebSocket(wsUri)
				setWsClient(client)

				client.onopen = event => {
					client.send(JSON.stringify([{
						verb: 'subscribe',
						entity: 'devicedata',
						params: [DeviceId]
					}]))
				}

				client.onclose = event => {
					console.log('Websocket Connection Closed', wsUri);
				}

				client.onmessage = event => {
					console.log("Received: '" + event.data + "'");
					try {
						const msg = JSON.parse(event.data)
						if (msg.data)
							deviceDataReceived(msg.data)
					}
					catch (e) {
						console.error("Failed to parse WS JSON")
					}
				}
			}
			catch (e) {
				console.error(e)
			}
		}
	}

	const deviceDataReceived = (deviceData: DeviceData) => {
		deviceData.CreatedAt = moment(deviceData.CreatedAt).toISOString()
		updateDD([deviceData])
	}

	const updateDeviceData = async () => {
		//console.log(`updateDD`)
		let d: any = null
		let uri = `devices/${deviceId}/data`

		//console.log('deviceData', JSON.stringify(deviceData, null, 2))

		try {
			if (deviceData.length)
				uri = `devices/${deviceId}/data?since=${deviceData[deviceData.length - 1].CreatedAt}`
			d = await callApi(uri)
		}
		catch (e) {
			console.log("exception:", e)
		}

		if (!d)			// no new data
			return

		updateDD(d)
	}

	const updateDD = (d: DeviceData[]) => {
		console.log('little d', JSON.stringify(d, null, 2))

		const ddNewRows = d.reverse() as unknown as DeviceData[]
		const ddOldRows = ddStateRef.current ?? []
		const ddRows = [...ddOldRows, ...ddNewRows]
		setDeviceData(ddRows)		// add new data
		//console.log('deviceData', JSON.stringify(deviceData))
		//console.log('ddRows udd', JSON.stringify(ddRows))

		if (ddRows.length) {
			const fieldNames = Object.keys(ddRows[0].Data)
			const newSeries = fieldNames.map(md => {
				return {
					name: md,
					data: ddRows.filter(row => !!Object.keys(row.Data).find(key => key === md))
						.map(row => { return { x: new Date(row.CreatedAt), y: ((Object.entries(row.Data).find(entry => entry[0] === md)) || [0, 1])[1] } })
				}
			})

			// Add a fake data point duplicating the last data point for this point in time
			if (newSeries[0] && newSeries[0].data?.length)
				newSeries[0].data.push({ x: new Date(), y: newSeries[0].data[newSeries[0].data.length - 1].y })
			//console.log(newSeries[0].data)

			setSeries(newSeries)
			//console.log(`set new series, len ${newSeries.length}`)
		}

		/*// Generate Points of Interest from non-number fields
		const points: any[] = [], xaxis: any[] = []
		metadata.forEach(field => {
			if (field.Type === MetadataType.Enum || field.Type === MetadataType.String) {
		
				let lastValue: number | string | null = null
				ddRows.forEach(dd => {
					const data = dd.Data as any
					if (data[field.Name] !== undefined) {
		
						const value = data[field.Name]
						//console.log("values", value, lastValue)
						if (lastValue !== null && value !== lastValue) {
							// Create annotation
							const anno = { ...AnnotationTemplate }
							anno.x = new Date(dd.CreatedAt).getTime()
							anno.y = value
							anno.label.text = `${field.Name} = ${value.toString()}`
							points.push(anno)
		
							const anno2 = { ...AnnoAxisTemplate, label: { ...AnnoAxisTemplate.label } }
							anno2.x = new Date(dd.CreatedAt).getTime()
							anno2.label.text = field.Description + ': ' + lastValue.toString() + ' ==> ' + value.toString()
							xaxis.push(anno2)
						}
						lastValue = value
					}
				})
			}
		})
		const newOptions = {
			...options,
			annotations: {
				xaxis: xaxis
				//points: points
			}
		}
		setOptions(newOptions) */
	}

	React.useEffect(() => {
		//console.log(`useE1`)
		getData(deviceTypeId, deviceId)
	}, [deviceTypeId, deviceId])

	React.useEffect(() => {
		//console.log(`useE2`)
		getDeviceData()
	}, [selectedFields])

	/*
	React.useEffect(() => {
		//console.log(`useE3`)
		const timer = setInterval(() => {
			updateDeviceData()
		}, 5000)
		return () => clearInterval(timer)
	}, [])
 */

	//console.log('series len', series.length, 'metadata len', metadata.length, 'selected len', selectedFields.length)

	/*
	const setChecked = (meta: DeviceMetadata, checked: boolean | string) => {
		if (checked) {
			setSelectedFields([...selectedFields, meta])
		}
		else {
			setSelectedFields(selectedFields.filter(field => field.FieldId !== meta.FieldId))
		}
	
	}
	*/

	const styles = useStyles();
	const labelId = useId("label");

	return (
		<div style={{ margin: '64px', width: '95%', minWidth: '1200px', minHeight: '800px' }}>


			{/*metadata.map(meta => (
				<Checkbox key={meta.FieldId} label={meta.Name} checked={!!selectedFields.find(field => field.FieldId === meta.FieldId)}
					onChange={(ev, data) => setChecked(meta, data.checked)} />
			))*/}
			<div className={styles.field}>
				<Label id={labelId}>Timescale</Label>
				<RadioGroup layout="horizontal" value={chartScale} onChange={(_, data) => setChartScale(data.value)} aria-labelledby={labelId}>
					<Radio value="0.1666667" label="10 minutes" />
					<Radio value="0.5" label="30 minutes" />
					<Radio value="1.0" label="1 hour" />
					<Radio value="2.0" label="2 hours" />
					<Radio value="8.0" label="8 hours" />
					<Radio value="24.0" label="24 hours" />
				</RadioGroup>
			</div>

			<br />
			<br />
			{series.map((ser, index) => (
				<ReactApexChart
					key={index}
					series={[ser]}
					options={{
						...options,
						//chart: { ...options.chart, id: index.toString() },
						stroke: { ...options.stroke, colors: [lineColors[index]] },
						title: { text: metadata.filter(md => md.Name === ser.name).map(md => md.Description)[0] }, //{ text: ser.name },
						labels: metadata.filter(md => md.Name === ser.name).map(md => md.Description),
						xaxis: { ...options.xaxis, min: Date.now() - parseFloat(chartScale) * 60 * 60 * 1000, max: Date.now() }
					}}
					width="1200px"
					height="180px" />)
			)}
		</div>
	)
}

export default DeviceDataCharts
