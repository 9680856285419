import { AgAuthContext } from "@silverfallscapital/agora-sdk";
import { API_BASE } from '../env'

type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

let authContext: AgAuthContext = new AgAuthContext(API_BASE)

export const isLoggedIn = async () => {
	return await authContext?.isLoggedIn() ?? false
}

export const logIn = async (username: string, password: string) => {
	try {
		await authContext.login(username, password)
		console.log('Logged in. Token', authContext?.UserToken)
		return authContext.User
	}
	catch (e) {
		console.log('Login failed', JSON.stringify(e, null, 2))
		return null
	}
}

export const callApi = async (endpoint: string, method: Method = 'GET', body?: string): Promise<any | null> => {

	const options: any = {
		method: method,
		headers: {
			"Content-Type": "application/json",
			"X-ApiToken": "mREFt86zqcWtp69Oif5kw1Sia3h4B9OZFF1ybqVGfmFx7UFCTbHWF80S6bKmXMvm"
		},
	}

	try {
		if (authContext && await authContext.isLoggedIn()) {
			options.headers["X-UserToken"] = authContext.UserToken
			console.log('Adding user token')
		}

		if (body)
			options.body = body

		const res = await fetch(`${API_BASE}/${endpoint}`, options)

		if (Math.floor(res.status / 100) > 2) {
			console.log(`Error ${res.status} calling /${endpoint}: ${(res as any).message}`)
		}

		return res.json()
	}
	catch (e) {
		console.error('Fetch exception:', e)
	}

	return null
}

export default callApi