import React from 'react'
import { Organization, PanelProps } from './types'
import { Button, Dropdown, InfoLabel, Input, Label, makeStyles, Option, shorthands } from '@fluentui/react-components'
import { Drawer } from '@fluentui/react-components/unstable'
import Grid from '@mui/joy/Grid'
import { ShareScreenStop24Filled } from '@fluentui/react-icons'
import { callApi } from './utils/api'


export interface OrgPanelProps extends PanelProps<Organization> {

}

const useStyles = makeStyles({
	root: {
		// Stack the label above the field
		display: "flex",
		flexDirection: "column",
		// Use 2px gap below the label (per the design system)
		...shorthands.gap("2px"),
		// Prevent the example from taking the full width of the page (optional)
		//maxWidth: "100%",
		fontSize: '10pt',
		marginBottom: '12px'
	},
});

const OrgPanel: React.FC<OrgPanelProps> = (props) => {
	const org = props.selectedObject
	const [theOrg, setTheOrg] = React.useState(org)
	const [orgList, setOrgList] = React.useState<Organization[]>([])

	React.useEffect(() => {
		setTheOrg(props.selectedObject)
	}, [props])

	React.useEffect(() => {
		callApi('orgs').then(res => setOrgList(res))
	}, [])

	const onParentOptionSelect = (optionValue: string | undefined, optionText: string | undefined) => {
		console.log("optionValue", optionValue);
		// TODO: make the arrays
		setTheOrg({ ...theOrg, ParentOrgIds: [optionValue], ParentNames: [optionText] } as Organization)
	}

	const styles = useStyles();
	return (
		<Drawer open={props.open} position='end' size='medium' modalType='non-modal'>
			<div style={{ padding: '32px', width: '500px' }}>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div><strong>ORGANIZATION</strong></div>
					<div><ShareScreenStop24Filled style={{ cursor: "pointer", color: "red" }} onClick={() => props.onClose()} /></div>
				</div>
				<br /><br /><br />

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt', float: 'left' }} htmlFor="orgId">Id</Label>
							<InfoLabel info="The org id cannot be changed" style={{ fontSize: '8pt', float: 'left' }} />
						</Grid>
						<Grid xs={9}>
							<Input appearance='underline' required id="orgId" value={theOrg?.OrgId || ''} disabled style={{ width: '100%' }} /><br />
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }} htmlFor="orgName">Name</Label>
						</Grid>
						<Grid xs={9}>
							<Input appearance='underline' required id="orgName" value={theOrg?.Name || ''} onChange={(e, d) => { setTheOrg({ ...theOrg, Name: d.value } as Organization) }} style={{ width: '100%' }} />
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }} htmlFor="parentName">Parent Org</Label>
						</Grid>
						<Grid xs={9}>
							<Dropdown placeholder="Organization" value={theOrg?.ParentNames ? theOrg?.ParentNames[theOrg?.ParentNames?.length - 1] : 'None'} onOptionSelect={(e, d) => onParentOptionSelect(d.optionValue, d.optionText)}>
								<Option key={'None'}>None</Option>
								{orgList.map((org) => (
									<Option key={org.OrgId} text={org.Name} value={org.OrgId} disabled={org.OrgId === theOrg?.OrgId || org.ParentOrgIds.includes(theOrg?.OrgId || "")}>
										{org.Name}
									</Option>
								))}
							</Dropdown>
						</Grid>
					</Grid>
				</div>

				<br /><br /><br />

				<Button appearance='primary' onClick={() => props.onSave(theOrg as Organization)}>Save</Button>
				&nbsp;&nbsp;
				<Button appearance='secondary' onClick={() => props.onClose()}>Close</Button>
			</div>
		</Drawer>
	)
}

export default OrgPanel