import React from 'react'
import { GlobalContext } from './App'
import CrudElement from './crud'
import { DeviceType } from './types'
import { TableCellLayout, TableColumnDefinition, createTableColumn } from '@fluentui/react-components'
import DeviceTypePanel from './deviceTypePanel'
import { callApi } from './utils/api'


const columns: TableColumnDefinition<DeviceType>[] = [
	createTableColumn<DeviceType>({
		columnId: "id",
		compare: (a, b) => {
			return a.DeviceTypeId.localeCompare(b.DeviceTypeId);
		},
		renderHeaderCell: () => {
			return "Id";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.DeviceTypeId}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<DeviceType>({
		columnId: "Description",
		compare: (a, b) => {
			return a.Description.localeCompare(b.Description);
		},
		renderHeaderCell: () => {
			return "Name";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.Description}
				</TableCellLayout>
			);
		},
	}),
]

const DeviceTypeList = () => {
	const { state, setState } = React.useContext(GlobalContext);

	const getDevices = async () => {
		if (state.Org)
			return await callApi(`orgs/${state.Org.OrgId}/devicetypes`)
		else
			return await callApi(`devicetypes`)
	}

	const filterObject = (ob: DeviceType, filterText: string): boolean => {
		return ob.Description.toLowerCase().includes(filterText)
	}

	const createDevice = (deviceType: DeviceType) => {

		return callApi('devicetypes', 'POST', JSON.stringify({ Name: deviceType.Description, DeviceTypeId: deviceType.DeviceTypeId }))
			.then(d => { console.log(d); })
	}

	const saveDevice = (deviceType: DeviceType) => {

		console.log("Saving device", deviceType)
		return callApi(`devicetypes/${deviceType.DeviceTypeId}`, 'PUT', JSON.stringify({ Name: deviceType.Description, DeviceCohortId: deviceType.DeviceCohortId, LocationId: deviceType.DeviceTypeId }))
			.then(d => { console.log(d); })
	}

	const isDeviceSelected = (): boolean => {
		return false
	}

	const findDevice = (list: DeviceType[], id: string) => {
		return list.find(a => a.DeviceTypeId === id) || null
	}

	return (
		<div style={{ margin: '32px' }}>
			{CrudElement<DeviceType>("Device Types", "Device Type", getDevices, filterObject, (o: DeviceType) => o.DeviceTypeId, createDevice, null, saveDevice, isDeviceSelected, columns, DeviceTypePanel, findDevice)}
		</div>
	)
}

export default DeviceTypeList;
