/*
**	AGORA Admin UI. (c) 2024 Silver Falls Capital. All Rights Reserved
**
**	This code is unlicensed and may not be copied, used, modified,
**	compiled, run, or distributed in any form without the explicit
**	written permission of Silver Falls Capital. 
**
**	Author(s): Oliver Sturrock olst@silverfallscapital.com
**
**/

import React from 'react'
import { Location, Device, DeviceData, PanelProps, DeviceEvent, DeviceMetadata, MetadataType } from './types'
import {
	tokens, Option, Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader,
	DataGridHeaderCell, DataGridRow, Dropdown, Input, Label, OnSelectionChangeData,
	TableCellLayout, TableColumnDefinition, createTableColumn, makeStyles, shorthands, Field,
	TabList, Tab, TabValue, SelectTabData, SelectTabEvent, Checkbox
} from '@fluentui/react-components'
import {
	Dialog,
	DialogTrigger,
	DialogSurface,
	DialogTitle,
	DialogContent,
	DialogBody,
	DialogActions,
} from "@fluentui/react-components";
import { Drawer } from '@fluentui/react-components/unstable'
import Grid from '@mui/joy/Grid'
import moment from 'moment'
import { ArrowCircleDown24Filled, ArrowCircleUp24Filled, Calendar24Regular, ShareScreenStop24Filled, Edit24Regular, Delete24Regular } from '@fluentui/react-icons'
import { callApi } from './utils/api';


export interface DevicePanelProps extends PanelProps<Device> {

}

const useFormStyles = makeStyles({
	content: {
		display: "flex",
		flexDirection: "column",
		rowGap: "10px",
	},
});


const colsDeviceData: TableColumnDefinition<ExpandedDeviceData>[] = [
	createTableColumn<ExpandedDeviceData>({
		columnId: "Name",
		compare: (a, b) => {
			return a.Name.localeCompare(b.Name);
		},
		renderHeaderCell: () => {
			return "Field";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.Name}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<ExpandedDeviceData>({
		columnId: "Value",
		compare: (a, b) => {
			return a.Value < b.Value ? -1 : (a.Value === b.Value ? 0 : 1)
		},
		renderHeaderCell: () => {
			return "Value";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.Value}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<ExpandedDeviceData>({
		columnId: "CreatedAt",
		compare: (a, b) => {
			return moment(a.CreatedAt).diff(b.CreatedAt);
		},
		renderHeaderCell: () => {
			return "Time";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{moment(item.CreatedAt).fromNow()}
				</TableCellLayout>
			);
		},
	}),
]

const colsEvents: TableColumnDefinition<DeviceEvent>[] = [
	createTableColumn<DeviceEvent>({
		columnId: "Icon",
		renderHeaderCell: () => {
			return "";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					<div style={{ marginTop: '4px' }}>{item.Icon}</div>
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<DeviceEvent>({
		columnId: "Name",
		compare: (a, b) => {
			return a.Name.localeCompare(b.Name);
		},
		renderHeaderCell: () => {
			return "Field";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.Name}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<DeviceEvent>({
		columnId: "OldValue",
		compare: (a, b) => {
			return a.OldValue.localeCompare(b.OldValue);
		},
		renderHeaderCell: () => {
			return "Old Value";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					<span style={{ color: '#9090A0' }}>{item.OldValue}</span>
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<DeviceEvent>({
		columnId: "NewValue",
		compare: (a, b) => {
			return a.NewValue < b.NewValue ? -1 : (a.NewValue === b.NewValue ? 0 : 1)
			//return a.NewValue.localeCompare(b.NewValue);
		},
		renderHeaderCell: () => {
			return "New Value";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.NewValue}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<DeviceEvent>({
		columnId: "CreatedAt",
		compare: (a, b) => {
			return moment(a.At).diff(b.At);
		},
		renderHeaderCell: () => {
			return "Time";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{moment(item.At).fromNow()}
				</TableCellLayout>
			);
		},
	})
]

const useStyles = makeStyles({
	root: {
		// Stack the label above the field
		display: "flex",
		flexDirection: "column",
		// Use 2px gap below the label (per the design system)
		...shorthands.gap("2px"),
		// Prevent the example from taking the full width of the page (optional)
		//maxWidth: "100%",
		fontSize: '10pt',
		marginBottom: '12px'
	},
	panels: {
		...shorthands.padding(0, "10px"),
		"& th": {
			textAlign: "left",
			...shorthands.padding(0, "10px", 0, 0),
		},
	},
	propsTable: {
		"& td:first-child": {
			fontWeight: tokens.fontWeightSemibold,
		},
		"& td": {
			...shorthands.padding(0, "30px", 0, 0),
		},
	},
});

interface ExpandedDeviceData {
	OrgId: string
	DeviceId: string
	Data?: object
	CreatedAt: string
	Name: string
	Value: string
}

const DevicePanel: React.FC<DevicePanelProps> = (props) => {
	const device = props.selectedObject
	const [theDevice, setTheDevice] = React.useState(device)
	const [objectList, setObjectList2] = React.useState<ExpandedDeviceData[]>([])
	const [locations, setLocations] = React.useState<Location[]>([])
	const [currentState, setCurrentState] = React.useState<ExpandedDeviceData[]>([])
	const [events, setEvents] = React.useState<any[]>([])
	const [dataKeys, setDataKeys] = React.useState<string[]>([])
	const [selectedDataKey, setSelectedDataKey] = React.useState<string>()
	const [metadata, setMetadata] = React.useState<DeviceMetadata[]>([])
	const [editMetaField, setEditMetaField] = React.useState<DeviceMetadata | null>(null);

	const setObjectList = (dd: DeviceData[]) => {
		// Split the data records where we have records containing multiple fields
		const results: ExpandedDeviceData[] = []
		const history: ExpandedDeviceData[] = []
		dd.forEach(ddr => {
			for (const [key, value] of Object.entries(ddr.Data)) {
				// store everything for further processing
				results.push({ CreatedAt: ddr.CreatedAt.toString(), DeviceId: ddr.DeviceId, OrgId: ddr.OrgId, Name: key, Value: value.toString() })
				if (!selectedDataKey || selectedDataKey === key)
					// sotre only filtered results for the history table
					history.push({ CreatedAt: ddr.CreatedAt.toString(), DeviceId: ddr.DeviceId, OrgId: ddr.OrgId, Name: key, Value: value.toString() })
			}
		})

		setObjectList2(history)

		let currState: ExpandedDeviceData[] = []
		const keys = new Set<string>()
		results.forEach(res => {
			keys.add(res.Name)
			if (!selectedDataKey || selectedDataKey === res.Name)
				if (!currState.find(cs => cs.Name === res.Name))
					currState.push(res)
		})

		setCurrentState(currState)
		setDataKeys(Array.from(keys.values()))

		currState = []
		const newEvents: any[] = []
		results.reverse().forEach(res => {
			const lastCS = currState.find(cs => cs.Name === res.Name)
			if (!selectedDataKey || selectedDataKey === res.Name) {
				if (lastCS) {
					// if the value changed, write an event record
					if (res.Value !== lastCS.Value) {
						let icon = <Calendar24Regular />
						const oldValue = parseFloat(lastCS.Value)
						const newValue = parseFloat(res.Value)
						if (oldValue && newValue) {
							if (oldValue < newValue)
								icon = <ArrowCircleUp24Filled style={{ color: 'green' }} />
							else if (oldValue > newValue)
								icon = <ArrowCircleDown24Filled style={{ color: 'red' }} />
						}
						const event: DeviceEvent = { Name: res.Name, OldValue: lastCS.Value, NewValue: res.Value, At: res.CreatedAt, Icon: icon }
						newEvents.push(event)

						// Now remove the last state for next time around
						//currState = currState.slice(currState.indexOf(lastCS))
						currState = currState.map(item => item.Name === res.Name ? { ...item, Value: res.Value, CreatedAt: res.CreatedAt } : item);
					}
				}
				else
					currState.push(res)
			}
		})

		setEvents(newEvents.reverse())
	}

	const getDeviceData = async (Device: Device | null): Promise<DeviceData[]> => {
		if (!Device)
			return [] as DeviceData[]

		callApi(`devicetypes/${Device.DeviceTypeId}/metadata`).then(res => { console.log(res); setMetadata(res) })
		callApi('locations').then(res => setLocations(res))

		return (await callApi(`devices/${Device.DeviceId}/data?rows=200`)) as DeviceData[]
	}

	const refresh = () => {
		getDeviceData(theDevice).then(d => setObjectList(d))
	}

	React.useEffect(() => {
		refresh()
	}, [selectedDataKey])

	React.useEffect(() => {
		setTheDevice(props.selectedObject)
		if (props.selectedObject)
			getDeviceData(props.selectedObject).then(d => setObjectList(d))
	}, [props])

	const onLocationOptionSelect = (optionValue: string | undefined, optionText: string | undefined) => {
		setTheDevice({ ...theDevice, LocationId: optionValue, LocationName: optionText } as Device)
	}

	const onKeyOptionSelect = (optionValue: string | undefined, optionText: string | undefined) => {
		setSelectedDataKey(optionValue)
	}

	const onSelectChange = (data: OnSelectionChangeData) => {
		/*	const newSelObId = data.selectedItems.size ? data.selectedItems.values().next().value : ''
			let newSelOb = findObject(objectList, newSelObId)
			setSelectedObject(newSelOb)
			setDrawerOpen(!!newSelOb)*/
	}

	const [selectedValue, setSelectedValue] = React.useState<TabValue>("currentstate")

	const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
		setSelectedValue(data.value);
	}

	const styles = useStyles();

	const colsMetadata: TableColumnDefinition<DeviceMetadata>[] = [
		createTableColumn<DeviceMetadata>({
			columnId: "Name",
			compare: (a, b) => {
				return a.Name.localeCompare(b.Name);
			},
			renderHeaderCell: () => {
				return "Field";
			},
			renderCell: (item) => {
				return (
					<TableCellLayout>
						{item.Name}
					</TableCellLayout>
				);
			},
		}),
		createTableColumn<DeviceMetadata>({
			columnId: "Description",
			compare: (a, b) => {
				return a.Description.localeCompare(b.Description);
			},
			renderHeaderCell: () => {
				return "Description";
			},
			renderCell: (item) => {
				return (
					<TableCellLayout>
						{item.Description}
					</TableCellLayout>
				);
			},
		}),
		createTableColumn<DeviceMetadata>({
			columnId: "Type",
			compare: (a, b) => {
				return a.Type - b.Type
			},
			renderHeaderCell: () => {
				return "Type";
			},
			renderCell: (item) => {
				return (
					<TableCellLayout>
						{MetadataType[item.Type]}
					</TableCellLayout>
				);
			},
		}),
		createTableColumn<DeviceMetadata>({
			columnId: "Keep History",
			compare: (a, b) => {
				return a.Type - b.Type
			},
			renderHeaderCell: () => {
				return "History";
			},
			renderCell: (item) => {
				return (
					<TableCellLayout>
						{item.IsTimeSeries ? "Yes" : "No"}
					</TableCellLayout>
				);
			},
		}),
		createTableColumn<DeviceMetadata>({
			columnId: "Actions",
			compare: (a, b) => {
				return a.Type - b.Type
			},
			renderHeaderCell: () => {
				return "Actions";
			},
			renderCell: (item) => {
				return (
					<TableCellLayout>
						<Button style={{ marginRight: '4px' }} size="small" onClick={() => onEditMeta(item)} icon={<Edit24Regular />} />
						<Button style={{ marginRight: '4px' }} size="small" onClick={() => onEditMeta(item)} icon={<Delete24Regular />} />
					</TableCellLayout>
				);
			},
		})
	]

	interface EditMetaFormProps {
		item: DeviceMetadata | null
	}

	const EditMetaForm = (props: EditMetaFormProps) => {
		const styles = useFormStyles();
		const handleSubmit = (ev: React.FormEvent) => {
			ev.preventDefault()
			alert("form submitted!")
			setEditMetaField(null)
		}
		const [item, setItem] = React.useState(props.item)
		return (
			<Dialog modalType="modal" inertTrapFocus={true} open={!!editMetaField}>
				<DialogSurface aria-describedby={undefined}>
					<form onSubmit={handleSubmit}>
						<DialogBody>
							<DialogTitle>Edit Device Data Field</DialogTitle>
							<DialogContent className={styles.content}>
								<div><strong style={{ color: 'red' }}>WARNING!</strong> The field name must match the name of the field that the device is sending. Changes to this field will be propagated to all devices of this device type.</div>
								<Label required htmlFor={"name-input"}>
									Name
								</Label>
								<Input required id={"name-input"} value={item?.Name} onChange={(e, d) => setItem({ ...item, Name: d.value } as DeviceMetadata)} />
								<Label required htmlFor={"description-input"}>
									Description
								</Label>
								<Input required id={"description-input"} value={item?.Description} />
								<Label required htmlFor={"type-input"}>
									Type
								</Label>
								<Dropdown id={"type-input"} value={MetadataType[item?.Type || 0]} >
									<Option value='0' text="String">String</Option>
									<Option value='1' text="Number">Number</Option>
									<Option value='2' text="Enum">Enum</Option>
									<Option value='3' text="Date">Date</Option>
								</Dropdown>
								<Checkbox label="Keep history for time-series analysis" id={"history-input"} value={item?.IsTimeSeries} />
							</DialogContent>
							<DialogActions>
								<DialogTrigger disableButtonEnhancement>
									<Button appearance="secondary" onClick={() => setEditMetaField(null)}>Close</Button>
								</DialogTrigger>
								<Button type="submit" appearance="primary">
									Submit
								</Button>
							</DialogActions>
						</DialogBody>
					</form>
				</DialogSurface>
			</Dialog>
		);
	}

	const onEditMeta = (item: DeviceMetadata) => {
		setEditMetaField(item)
	}

	const History: React.FC = () => {
		return (<DataGrid
			columns={colsDeviceData}
			items={objectList}
			getRowId={item => item.createdAt}
			onSelectionChange={(e, data) => { onSelectChange(data) }}
			sortable
			//selectionMode="single"
			style={{ fontSize: '9pt', overflow: "auto", height: "400px" }}
		>
			<DataGridHeader>
				<DataGridRow >
					{({ renderHeaderCell }) => (
						<DataGridHeaderCell><strong>{renderHeaderCell()}</strong></DataGridHeaderCell>
					)}
				</DataGridRow>
			</DataGridHeader>
			<DataGridBody<DeviceData>>
				{({ item, rowId }) => (
					<DataGridRow<DeviceData>
						key={rowId}
					//selectionCell={{ "aria-label": "Select row" }}
					>
						{({ renderCell }) => (
							<DataGridCell>{renderCell(item)}</DataGridCell>
						)}
					</DataGridRow>
				)}
			</DataGridBody>
		</DataGrid>
		)
	}

	const Events: React.FC = () => {
		return (<DataGrid
			columns={colsEvents}
			items={events}
			getRowId={item => item.createdAt}
			onSelectionChange={(e, data) => { onSelectChange(data) }}
			sortable
			resizableColumns
			columnSizingOptions={{
				Icon: {
					minWidth: 20,
					defaultWidth: 20,
				},
				Name: {
					minWidth: 160,
					defaultWidth: 160,
				},
				OldValue: {
					minWidth: 80,
					defaultWidth: 80,
				},
				NewValue: {
					minWidth: 80,
					defaultWidth: 80,
				}
			}}
			//selectionMode="single"
			style={{ fontSize: '9pt', overflow: "auto", height: "400px" }}
		>
			<DataGridHeader>
				<DataGridRow >
					{({ renderHeaderCell }) => (
						<DataGridHeaderCell><strong>{renderHeaderCell()}</strong></DataGridHeaderCell>
					)}
				</DataGridRow>
			</DataGridHeader>
			<DataGridBody<DeviceEvent>>
				{({ item, rowId }) => (
					<DataGridRow<DeviceEvent>
						key={rowId}
					//selectionCell={{ "aria-label": "Select row" }}
					>
						{({ renderCell }) => (
							<DataGridCell>{renderCell(item)}</DataGridCell>
						)}
					</DataGridRow>
				)}
			</DataGridBody>
		</DataGrid>
		)
	}

	const CurrentState: React.FC = () => {

		return (<DataGrid
			columns={colsDeviceData}
			items={currentState}
			getRowId={item => item.createdAt}
			onSelectionChange={(e, data) => { onSelectChange(data) }}
			sortable
			//selectionMode="single"
			style={{ fontSize: '9pt', overflow: "auto", height: "400px" }}
		>
			<DataGridHeader>
				<DataGridRow >
					{({ renderHeaderCell }) => (
						<DataGridHeaderCell><strong>{renderHeaderCell()}</strong></DataGridHeaderCell>
					)}
				</DataGridRow>
			</DataGridHeader>
			<DataGridBody<DeviceData>>
				{({ item, rowId }) => (
					<DataGridRow<DeviceData>
						key={rowId}
					//selectionCell={{ "aria-label": "Select row" }}
					>
						{({ renderCell }) => (
							<DataGridCell>{renderCell(item)}</DataGridCell>
						)}
					</DataGridRow>
				)}
			</DataGridBody>
		</DataGrid>)
	}

	const Metadata: React.FC = () => {

		return (<DataGrid
			columns={colsMetadata}
			items={metadata}
			getRowId={item => item.createdAt}
			onSelectionChange={(e, data) => { onSelectChange(data) }}
			sortable
			//selectionMode="single"
			style={{ fontSize: '9pt', overflow: "auto", height: "400px" }}
		>
			<DataGridHeader>
				<DataGridRow >
					{({ renderHeaderCell }) => (
						<DataGridHeaderCell><strong>{renderHeaderCell()}</strong></DataGridHeaderCell>
					)}
				</DataGridRow>
			</DataGridHeader>
			<DataGridBody<DeviceData>>
				{({ item, rowId }) => (
					<DataGridRow<DeviceData>
						key={rowId}
					//selectionCell={{ "aria-label": "Select row" }}
					>
						{({ renderCell }) => (
							<DataGridCell>{renderCell(item)}</DataGridCell>
						)}
					</DataGridRow>
				)}
			</DataGridBody>
		</DataGrid>)
	}

	return (
		<Drawer open={props.open} position='end' size='medium' modalType='non-modal'>
			<div style={{ padding: '32px', width: '500px' }}>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div><strong>DEVICE</strong></div>
					<div><ShareScreenStop24Filled style={{ cursor: "pointer", color: "red" }} onClick={() => props.onClose()} /></div>
				</div>
				<br /><br /><br />

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt', float: 'left' }} htmlFor="orgId">Id</Label>
						</Grid>
						<Grid xs={9}>
							<Label id="orgId" style={{ width: '100%' }}>{theDevice?.DeviceId || ''}</Label><br />
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }} htmlFor="orgName">Name</Label>
						</Grid>
						<Grid xs={9}>
							<Input appearance='underline' required id="orgName" value={theDevice?.Name || ''} onChange={(e, d) => { setTheDevice({ ...theDevice, Name: d.value } as Device) }} style={{ width: '100%' }} />
						</Grid>
					</Grid>
				</div>

				{/*<Field
					label="Example field"
					validationState="success"
					validationMessage="This is a success message.">
					<Input />
				</Field>*/}

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }} htmlFor="parentName">Location</Label>
						</Grid>
						<Grid xs={9}>
							<Dropdown placeholder="Organization" value={locations.find(loc => loc.LocationId === theDevice?.LocationId)?.Description || 'None'} onOptionSelect={(e, d) => onLocationOptionSelect(d.optionValue, d.optionText)}>
								<Option key={'None'}>None</Option>
								{locations.map((loc) => (
									<Option key={loc.LocationId} text={loc.Description} value={loc.LocationId}>
										{loc.Description}
									</Option>
								))}
							</Dropdown>
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }}>Last Report At</Label>
						</Grid>
						<Grid xs={9}>
							<Label>{theDevice?.LastSeenAt ? moment(theDevice?.LastSeenAt).format('YYYY-MM-DD h:mm:ssa') : "never"} <strong style={{ color: 'red' }}>{moment(theDevice?.LastSeenAt).add(4, 'hours') < moment() ? "(more than 4 hours ago)" : ""}</strong></Label>
						</Grid>
					</Grid>
				</div>

				<br /><br /><br />

				<div><strong>DEVICE DATA</strong></div><br />

				<div className={styles.root}>
					<EditMetaForm item={editMetaField} />

					<div className={styles.root}>
						<Grid container>
							<Grid xs={9}>
								<TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
									<Tab id="Current State" value="currentstate">
										Current State
									</Tab>
									<Tab id="Events" value="events">
										Changes
									</Tab>
									<Tab id="Data History" value="devicedata">
										History
									</Tab>
									<Tab id="Field Types" value="metadata">
										Field Types
									</Tab>
								</TabList>

							</Grid>
							<Grid xs={3} style={{ marginTop: '-12px' }}>
								<Field label="Filter by property" size='small'>
									<Dropdown style={{ minWidth: '120px' }} value={selectedDataKey || 'None'} onOptionSelect={(e, d) => onKeyOptionSelect(d.optionValue, d.optionText)}>
										<Option key={'None'} value={""}>None</Option>
										{dataKeys.map((key) => (
											<Option key={key} text={key} value={key}>
												{key}
											</Option>
										))}
									</Dropdown>
								</Field>
							</Grid>
						</Grid>
					</div>


					<div className={styles.panels}>
						{selectedValue === "devicedata" && <History />}
						{selectedValue === "events" && <Events />}
						{selectedValue === "currentstate" && <CurrentState />}
						{selectedValue === "metadata" && <Metadata />}
					</div>
				</div>



				<br /><br /><br />

				<div style={{ position: "absolute", bottom: '32px' }}>
					<Button appearance='primary' onClick={() => props.onSave(theDevice as Device)}>Save</Button>
					&nbsp;&nbsp;
					<Button appearance='secondary' onClick={() => props.onClose()}>Close</Button>
				</div>
			</div>
		</Drawer>
	)
}

export default DevicePanel