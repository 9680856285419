import React from 'react'
import Grid from '@mui/joy/Grid'
import { GlobalContext } from './App'
import { Button, Checkbox, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, Input, OnSelectionChangeData, TableColumnDefinition, ToolbarGroup } from "@fluentui/react-components";
import { Toolbar, ToolbarButton, ToolbarDivider } from "@fluentui/react-components";
import { PanelProps } from './types';
import { AddCircle24Regular } from '@fluentui/react-icons';


function CrudElement<Type>(
	title: string,
	objectTypeName: string,
	getObjects: (() => Promise<Type[] | null>),
	filterObject: ((ob: Type, filterText: string) => boolean),
	getId: ((ob: Type) => string),
	createObject: ((ob: Type) => void),
	selectObject: ((ob: Type | null) => void) | null,
	saveObject: ((ob: Type) => void),
	isSelected: (() => boolean),
	columns: TableColumnDefinition<Type>[],
	PropsPanel: React.FC<PanelProps<Type>> | null,
	findObject: ((list: Type[], id: string) => Type | null) = (list, id) => null,
	needSelectedOrg: boolean = true
) {

	const [showDeleted, setShowDeleted] = React.useState(false)
	const [objectList, setObjectList] = React.useState<Type[]>([])
	const [filteredList, setFilteredList] = React.useState<Type[]>([])
	const { state, setState } = React.useContext(GlobalContext);
	const [selectedObject, setSelectedObject] = React.useState<Type | null>(null);
	const [drawerOpen, setDrawerOpen] = React.useState(false)
	const [filterText, setFilterText] = React.useState("")
	const [refreshCount, setRefreshCount] = React.useState(0)

	const refresh = () => {
		getObjects().then(d => { if (d) setObjectList(d) })
	}

	React.useEffect(() => {
		refresh()
		//setTimeout(() => setRefreshCount(refreshCount + 1), 5000)
	}, [refreshCount])

	React.useEffect(() => {
		const filterTextLower = filterText.toLowerCase()
		const filtered: Type[] = []
		objectList.forEach(ob => {
			if (filterText.length === 0 || filterObject(ob, filterTextLower))
			filtered.push(ob)
		})
		setFilteredList(filtered)
	}, [objectList, filterText])

	const selectSelectedObject = () => {
		if (selectObject)
			selectObject(selectedObject)
	}

	const deleteSelectedObject = () => {
		if (selectedObject) {
			//deleteObject(selectedObject)
		}
	}

	const onSelectChange = (data: OnSelectionChangeData) => {
		const newSelObId = data.selectedItems.size ? data.selectedItems.values().next().value : ''
		let newSelOb = findObject(filteredList, newSelObId)
		setSelectedObject(newSelOb)
		setDrawerOpen(!!newSelOb)
	}

	const onSave = (newObject: Type) => {

		console.log("onSave", newObject);
		saveObject(newObject as unknown as Type);

		const newObjectList: Type[] = []
		objectList.forEach(oldObject => {
			if (getId(oldObject) === getId(newObject))
				newObjectList.push(newObject)
			else
				newObjectList.push(oldObject)
		})
		setObjectList(newObjectList)

		setDrawerOpen(false)
	}

	return (
		<div style={{ padding: '32px' }}>
			<Grid container>
				<Grid xs={12} style={{ fontSize: '13pt', fontWeight: 'bold' }}>
					{title.toUpperCase()}
				</Grid>
				<Grid xs={12} style={{ fontSize: '11pt' }}>
					{state.Org ? <span>Filtered to organzation {state.Org.Name} ({state.Org.OrgId})</span> : <span>Manage {objectTypeName.toLowerCase()}s</span>}
				</Grid>
				<Grid xs={12}>&nbsp;</Grid>

				<Grid xs={12} style={{ fontSize: '11pt' }}>
					<Toolbar aria-label="Default">
						<ToolbarButton appearance="primary" icon={<AddCircle24Regular />} aria-label="New" onClick={() => { selectSelectedObject() }}>New</ToolbarButton>
						<ToolbarButton aria-label="Delete" disabled={!selectedObject} onClick={() => { deleteSelectedObject() }}>Delete</ToolbarButton>
						{/*}
						{selectObject && <ToolbarDivider />}
						{selectObject && <ToolbarButton aria-label="Select" disabled={!selectedObject} onClick={() => { selectSelectedObject() }}>Select</ToolbarButton>}
						<ToolbarDivider />
						{selectObject && <ToolbarButton aria-label="Clear" disabled={!isSelected()} onClick={() => selectObject(null)}>Clear Selection</ToolbarButton>}
						*/}
						<ToolbarButton aria-label="Refresh" onClick={() => refresh()}>Refresh</ToolbarButton>
						<ToolbarDivider />
						<ToolbarGroup role="filters">
								<Input placeholder="filter" value={filterText} onChange={(e, d) => setFilterText(d.value)} />
								<Checkbox label="Show Deleted" checked={showDeleted} onChange={() => setShowDeleted(!showDeleted)} />
						</ToolbarGroup>
					</Toolbar>

				</Grid>

				<Grid xs={12}>&nbsp;</Grid>

				<Grid xs={12}>
					<DataGrid
						columns={columns}
						items={filteredList}
						getRowId={(item) => getId(item)}
						onSelectionChange={(e, data) => { onSelectChange(data) }}
						sortable
						selectionMode="single"
					>
						<DataGridHeader>
							<DataGridRow>
								{({ renderHeaderCell }) => (
									<DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
								)}
							</DataGridRow>
						</DataGridHeader>
						<DataGridBody<Type>>
							{({ item, rowId }) => (
								<DataGridRow<Type>
									key={rowId}
								//selectionCell={{ "aria-label": "Select row" }}
								>
									{({ renderCell }) => (
										<DataGridCell>{renderCell(item)}</DataGridCell>
									)}
								</DataGridRow>
							)}
						</DataGridBody>
					</DataGrid>
				</Grid>

				<Grid xs={12}>&nbsp;</Grid>

				{PropsPanel && <PropsPanel selectedObject={selectedObject as unknown as Type} open={drawerOpen} onClose={() => { console.log("onClose"); setDrawerOpen(false) }} onSave={onSave} />}

			</Grid>
		</div>
	)
}

export default CrudElement
