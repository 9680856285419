import React from 'react'
import { GlobalContext } from './App'
import CrudElement from './crud'
import { User } from './types'
import { Avatar, TableCellLayout, TableColumnDefinition, createTableColumn } from '@fluentui/react-components'
import userPanel from './userPanel'
import callApi from './utils/api'


const columns: TableColumnDefinition<User>[] = [
	/*createTableColumn<User>({
		columnId: "id",
		compare: (a, b) => {
			return a.UserId.localeCompare(b.UserId);
		},
		renderHeaderCell: () => {
			return "Id";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.UserId}
				</TableCellLayout>
			);
		},
	}),*/
	createTableColumn<User>({
		columnId: "Name",
		compare: (a, b) => {
			return a.Name.localeCompare(b.Name);
		},
		renderHeaderCell: () => {
			return "Name";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout
					media={
						<Avatar
							aria-label={item.Name}
							name={item.Name}
							badge={{ status: 'available' }}
						/>
					}
				>
					{item.Name}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<User>({
		columnId: "Email",
		compare: (a, b) => {
			return a.PrimaryEmail.localeCompare(b.PrimaryEmail);
		},
		renderHeaderCell: () => {
			return "Email";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.PrimaryEmail}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<User>({
		columnId: "Phone",
		compare: (a, b) => {
			return a.PrimaryPhone.localeCompare(b.PrimaryPhone);
		},
		renderHeaderCell: () => {
			return "Phone";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.PrimaryPhone}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<User>({
		columnId: "lastUpdated",
		compare: (a, b) => {
			return a.UpdatedAt - b.UpdatedAt;
		},
		renderHeaderCell: () => {
			return "Last updated";
		},

		renderCell: (item) => {
			return item.UpdatedAt;
		},
	}),
]

const UserList = () => {
	const {state, setState} = React.useContext(GlobalContext);

	const getUsers = async () => {
		if (state.Org)
			return await callApi(`orgs/${state.Org.OrgId}/users`)
		else
			return await callApi('users')
	}


	const filterObject = (ob: User, filterText: string): boolean => {
		return ob.Name.toLowerCase().includes(filterText)
	}

	const createUser = (user: User) => {

		console.log("Creating", user)
		return callApi('users', 'POST', JSON.stringify({ Name: user.Name, OrgId: state.Org ? state.Org.OrgId : null}))
			.then(d => { console.log(d); })
	}

	const saveUser = (user: User) => {

		console.log("Creating", user)
		return callApi('users', 'PUT', JSON.stringify({ Name: user.Name, OrgId: user.OrgId, PrimaryEmail: user.PrimaryEmail, PrimaryPhone: user.PrimaryPhone }))
			.then(d => { console.log(d); })
	}


	const selectUser = (user: User | null) => {
		setState({
			...state,
			User: user
		})
	}

	const isUserSelected = (): boolean => {
		return !!(state.User)
	}

	const findUser = (list: User[], id: string) => {
		return list.find(a => a.UserId === id) || null
	}

	return (
		<div style={{ margin: '32px' }}>
			{CrudElement<User>("Users", "User", getUsers, filterObject, (o: User) => o.UserId, createUser, selectUser, saveUser, isUserSelected, columns, userPanel, findUser)}
		</div>
	)
}

export default UserList;
